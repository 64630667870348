import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import JotformEmbed from 'react-jotform-embed'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const PageContactSectionNew = () => (
  <>
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        className="hidden md:block"
      />

      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={800}
        className="block md:hidden"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Contact Us</span>
            </h2>

            <h1 className='w-full lg:w-9/12 md:text-xl text-white text-lg text-center text-shadow-blue my-6 px-4 md:px-0'><span className='leading-loose'>For questions or bid information, please visit, call, email, or use contact form below.</span></h1>
          </StyledDiv>
        </div>
      </div>
    </div>

    <div className="w-full bg-gray-100">
      <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-14 md:py-20 lg:py-24 lg:w-10/12 mx-auto'>
        <div className='flex flex-col md:flex-row items-start justify-centerg'>
          <div className='w-full lg:w-3/6 flex flex-col justify-center mb-10 lg:mb-0'>
            <div className='mb-6'>
              <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Visit Our Locations</h2>

              <StyledUnderline className='mb-6'></StyledUnderline>

              <p className='text-site-blue text-lg leading-relaxed pr-0 md:pr-8'>We have several executive offices to better serve our communities.</p>
            </div>

            <div className='w-full md:w-11/12 bg-site-blue flex flex-col items-center justify-center px-4 py-6'>
              <div className='w-full flex flex-col items-center justify-center'>
                <p className="text-2xl md:text-3xl text-white font-semibold text-center mt-4">
                  Orange County Corporate Office
                </p>
                <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
                  2603 Main Street, Suite 210, Irvine, CA 92614
                </p>
                <div className="flex items-center mb-4">
                  <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
                  <a href="tel:949-833-8334" className="text-white text-xl font-semibold hover:underline ml-1">949-833-8334</a>
                </div>

                <div className="flex flex-col items-center justify-center mt-2 mb-6">
                  <a
                    href='https://goo.gl/maps/15cHJZxWZbMQyP5c6'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                </div>
              </div>

              <div className='w-full flex flex-col items-center justify-center'>
                <p className="text-2xl md:text-3xl text-white font-semibold text-center mt-4">
                  Los Angeles Office
                </p>
                <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
                  6345 N. Balboa Boulevard, Suite 280, Encino, CA 91316
                </p>
                <div className="flex items-center mb-4">
                  <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
                  <a href="tel:818-817-0550" className="text-white text-xl font-semibold hover:underline ml-1">818-817-0550</a>
                </div>

                <div className="flex flex-col items-center justify-center mt-2 mb-6">
                  <a
                    href='https://goo.gl/maps/suskUquaYF3HrWzQ9'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                </div>
              </div>

              <div className='w-full flex flex-col items-center justify-center'>
                <p className="text-2xl md:text-3xl text-white font-semibold text-center mt-4">
                  Inland Empire Office
                </p>
                <p className="text-xl text-site-red font-semibold text-center mt-2 mb-4">
                  685 E. Carnegie Drive, Suite 110, San Bernardino, CA 92408
                </p>
                <div className="flex items-center mb-4">
                  <PhoneIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
                  <a href="tel:949-833-8334" className="text-white text-xl font-semibold hover:underline ml-1">949-833-8334</a>
                </div>

                <div className="flex flex-col items-center justify-center mt-2 mb-6">
                  <a
                    href='https://maps.app.goo.gl/iJ5E643pzcsBWD3t8'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='w-full md:w-auto bg-site-blue hover:bg-blue-900 text-white text-lg text-center tracking-wider uppercase font-extrabold border-4 py-2 px-12 md:px-8'
                  >
                    Get Directions
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full lg:w-3/6 flex flex-col justify-center'>
            <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Contact Us</h2>

            <StyledUnderline className='mb-6'></StyledUnderline>

            <p className='text-site-blue text-lg leading-relaxed mb-6'>You may contact us directly via email or use the contact form below.</p>

            <div className="flex items-center -mb-12 lg:mb-6">
              <MailIcon className="h-6 w-6 text-site-red" aria-hidden="true" />
              <a href="mailto:info@tiarna.com" className="text-site-blue text-xl font-semibold hover:underline ml-2">info@tiarna.com</a>
            </div>

            <div>
              <JotformEmbed src="https://form.jotform.com/230711311706039" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PageContactSectionNew