import * as React from "react"

import LayoutPage from "../components/layout/layout-page"
import Seo from "../components/seo"
import PageContactSectionNew from "../components/sections/page-contact-section-new"

const ContactPage = () => (
  <LayoutPage isContactPage>
    <Seo title="Contact Us" />

    <PageContactSectionNew />
  </LayoutPage>
)

export default ContactPage
